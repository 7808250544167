import React, { Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { FullPageLoading } from "./components/ui/Loading";
import { lazyLoadComponent } from "./utils/react";

const SUBDOMAINS_WEBSITE = ["book", "web", "club"];
const SUBDOMAINS_WEBCHECKIN = ["checkin"];
const SUBDOMAINS_ZOBU = ["zobu", "new"];
const SUBDOMAINS_ADMIN = ["crs", "admin"];

const Website = lazyLoadComponent(() => import("./apps/Website"));
const Admin = lazyLoadComponent(() => import("./apps/Admin"));
const WebCheckIn = lazyLoadComponent(() => import("./apps/WebCheckIn"));
const Chat = lazyLoadComponent(() => import("./apps/Chat"));

const getApp = () => {
  const subdomain = window.location.host.split(".")[0];
  if (
    SUBDOMAINS_WEBSITE.indexOf(subdomain) !== -1 ||
    window.location.origin === "https://www.zostel.com"
  ) {
    return <Website />;
  } else if (SUBDOMAINS_WEBCHECKIN.indexOf(subdomain) !== -1) {
    return <WebCheckIn />;
  } else if (SUBDOMAINS_ZOBU.indexOf(subdomain) !== -1) {
    return <Chat />;
  } else if (SUBDOMAINS_ADMIN.indexOf(subdomain) !== -1) {
    if (window.location.pathname.startsWith("/chat")) return <Chat />;
    return <Admin />;
  } else if (
    subdomain.includes("localhost") ||
    window.location.host.includes("192.168")
  ) {
    //DEV
    return <Website />;
  } else {
    return <div>404</div>;
  }
};

interface AppProps {}

const App: React.FC<AppProps> = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<FullPageLoading text="Follow your ❤️" />}>
        <HelmetProvider>{getApp()}</HelmetProvider>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
